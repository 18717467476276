@blueGreyBackground: #ebebeb;
@redColour: #c9302c;

.img-border {
  border: 1px solid @gray-lighter;
}

/* buttons */

.btn-brand {
  .button-variant(#ffffff; @btn-primary-bg; @btn-primary-border);
}

.btn-rounded {
  .button-size(
    @padding-base-vertical; @padding-base-horizontal*2; @font-size-base;
      @line-height-base; 16px
  );
}
.btn-lg-rounded {
  .button-size(10px; 20px; @font-size-large; @line-height-large; 22px);
  @media (min-width: @screen-sm-min) {
    .button-size(10px; 36px; @font-size-large; @line-height-large; 22px);
  }
}

.bg-brand,
.btn-action {
  color: #fff;
  &:hover,
  &:focus {
    color: #fff;
  }
}

.border-brand {
  border-style: solid;
}

.bg-neutral {
  background-color: @blueGreyBackground;
}

// Sticky footer
html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 150px;
}

#main-content {
  height: 100%;
}
// END Sticky footer

////// Standard overrides /////////

h1 {
  font-size: 2.8em;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 2em;
  margin-top: 0px;
  margin-bottom: 0.3em;
}

h3 {
  font-size: 1.6em;
}

h4 {
  font-size: 1.4em;
  margin-top: 0px;
  margin-bottom: 0.3em;
}

h5 {
  text-transform: uppercase;
  font-size: 1.2em;
  margin-top: 0px;
  margin-bottom: 0.3em;
}

h6 {
  font-size: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-font-smoothing: antialiased;

  &.boxed {
    padding: 15px;
    margin-top: 0;
    background-color: @blueGreyBackground;
    color: @text-color;
    &.bg-brand {
      color: #fff;
    }
    a {
      color: @text-color;
    }
  }
}

a:focus,
.btn:focus {
  outline: none;
  text-decoration: none;
}

a:hover,
a:focus,
.btn.btn-link:hover {
  text-decoration: none;
}

img.border {
  border: 1px solid #999999;
}

.form-control {
  box-shadow: none;
  border-radius: 0;
}

.text-normal {
  color: @text-color;
}

// prevent padding on columns
.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.planModal,
.mapModal {
  .modal-dialog {
    margin-top: 30px;
    max-width: none;
    @media (min-width: @screen-sm-min) {
      width: 80%;
    }
    @media (min-width: @screen-md-min) {
      width: 60%;
    }
  }
}

.mapModal .address {
  margin-bottom: 10px;
}

.modal-dialog {
  width: 50%;
  max-width: 700px;
  margin-top: 10%;

  .modal-content {
    //background: @blueGreyBackground;
    position: relative;
    /* background-color: #fff; */
    border: 0px;
    box-shadow: initial;
    background-clip: initial;
    outline: 0;
    border-radius: 4px;

    .modal-header {
      padding: 10px 15px 10px 15px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      box-sizing: border-box;

      button.close {
        color: white;
        opacity: 1;
      }

      .modal-title {
        text-transform: none;
        color: white;
        text-align: center;
        font-size: 20px;
        font-weight: normal;
        padding-left: 44px;
        padding-right: 44px;
      }
      button {
        margin-top: 2px;
      }

      @media (min-width: @screen-sm-min) {
        padding: 20px 24px 15px 24px;
      }
    }

    .modal-body {
      padding: 10px;
      background-color: white;
      padding: 10px;
      line-height: 1.4;
      padding: 30px;
      background-color: #fff;
      box-sizing: border-box;
      width: 100%;
      text-align: center;
      color: #444;
      margin: 0px;
      font-size: 15px;

      iframe {
        background: url("../../img/ajax-loader.gif") no-repeat center;
      }

      pre {
        white-space: pre-wrap;
        word-break: normal;
      }

      @media (min-width: @screen-sm-min) {
        padding: 20px 24px;
      }
    }
    .modal-footer {
      background: #f6f7f9;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      text-align: center;

      button {
        font-size: 1.2em;
        outline: 0;
        font-size: 16px;
        line-height: 36px;
        display: inline-block;
        min-height: 36px;
        padding: 0 20px;
        font-weight: normal;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        white-space: nowrap;
        border-radius: 3px;
        box-shadow: none;
        cursor: pointer;
        color: #fff;
        border: 0;
        text-transform: none;
        // border-radius: 18px;
        border-radius: 0px;
        padding: 0px 20px;
        margin-right: 15px;
      }

      .btn + .btn {
        margin-left: 0.5em;
      }
    }
  }
}

.navbar-default {
  background-color: @blueGreyBackground;
  .navbar-nav > li.active {
    a,
    a:hover {
      background-color: @blueGreyBackground;
      border-bottom-style: solid;
      border-bottom-width: 4px;
      padding-bottom: (@navbar-padding-vertical - 4px);
    }
  }
}

.breadcrumb {
  background: none;
  padding-left: 0;
  margin-bottom: 0;
  font-size: 1.1em;
}

////// END Standard overrides /////////

.alert {
  text-align: center;
  font-size: 1.1em;

  &.error {
    .alert-danger();
  }
  &.info {
    .alert-info();
  }
  &.warning {
    .alert-warning();
  }
  &.success {
    .alert-success();
  }
  button.close {
    padding-left: 0.3em;
  }

  // &.restricted-view {
  //   color: @redColour;
  // }
}

header .spacer {
  height: 10px;
}

.branding-banner {
  margin: 15px 0;

  .branding-logo img {
    max-height: 100px;
  }

  p {
    font-family: @headings-font-family;
    text-align: right;
    margin-bottom: 0px !important;
  }

  p.primary {
    line-height: 1.2em;
    padding-top: 1.5em;
    font-size: 22px;
  }
  p.secondary {
    padding-top: 0.1em;
    font-size: 18px;
  }
}

.navbar-collapse.in {
  overflow-y: visible;
}

.navbar {
  border-radius: 0px;
  font-family: @headings-font-family;
  font-size: 1.2em;
  text-transform: uppercase;

  .navbar-input-mixin() {
    height: 38px;
    font-size: 15px;
  }

  .navbar-form {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .navbar-search {
    padding-left: 0;

    .searchbox input {
      width: 240px;
      border: 1px solid lighten(#000000, 60%);
      border-radius: 0px;
      .navbar-input-mixin;
    }

    .searchButton {
      .navbar-input-mixin;
      padding-top: 9px;
      padding-left: 11px;
      padding-right: 11px;
    }

    .keyword-missing input {
      border: 2px solid @redColour;
    }
  }

  .navbar-header {
    .navbar-search {
      margin: 0 auto;
      overflow: auto;
      padding: 8px 10px;
      .searchbox {
        max-width: 300px;
        .pull-right;
        input {
          width: 100%;
          padding-right: 6px;
          padding-left: 6px;
        }
      }
    }

    .navbar-toggle {
      .navbar-input-mixin;
      float: left;
      margin: 9px 10px;
    }
  }

  .cart-button {
    .navbar-input-mixin;
    padding-left: 10px;
    padding-left: 11px;
    padding-right: 11px;
  }

  .reservation-countdown {
    margin-right: 5px;

    .timer-val {
      color: @text-color;
    }
  }

  .dropdown-menu {
    background-color: @blueGreyBackground;
    text-transform: none;
    width: 280px;

    .cart-title {
      font-size: 1.2em;
      font-weight: 700;
      text-align: center;
      padding: 5px 0;
      border-bottom: 2px dotted #939393;
    }

    .cart-event {
      font-family: @font-family-base;
      padding: 10px;
      border-bottom: 2px dotted #939393;

      img {
        margin-right: 15px;
      }

      .cart-event-header {
        display: table;

        h4 {
          font-weight: bold;
          display: table-cell;
          vertical-align: middle;
          height: 50px;
        }
      }

      p.date {
        padding-top: 5px;
        font-weight: 700;
      }

      .cart-event-icons() {
        padding: 0 0 5px 30px;
        background-repeat: no-repeat;
        background-image: url("../../img/confirmed-icons.png");
        margin: 0;
      }

      p.location {
        .cart-event-icons;
        background-position: 2px -226px;
      }

      p.tickets {
        .cart-event-icons;
        background-position: 2px -457px;
      }

      .key {
        font-weight: bold;
        width: 70px;
        float: left;
        padding-right: 5px;
        margin-left: -5px;
      }

      .value {
        width: 160px;
        float: left;
      }
    }

    .total {
      background: #fff;
      text-align: center;
      font-size: 1.5em;
      font-weight: 700;
      border-bottom: 2px dotted #939393;
    }

    .buttons {
      padding: 10px;

      .btn-cont {
        white-space: nowrap;
      }

      a.btn {
        text-transform: uppercase;
        padding: 6px 10px 4px 10px;
      }

      .edit {
        background-color: @gray-lighter;
        float: left;
      }

      .checkout {
        color: #ffffff;
        float: right;
      }
    }
  }
}

.banner-cont {
  width: 100%;
  img {
    max-width: 100%;
  }
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: @blueGreyBackground;
  padding-top: 1.5em;
  padding-bottom: 0.75em;

  .seals .ing-logo {
    display: inline-block;
    margin: 5px 0;
  }

  .static-links ul {
    padding: 16px 0;
    margin: 0 auto;
    float: right;

    li {
      float: left;
      list-style: none;
      line-height: 1;
      padding-bottom: 0.8em;

      a {
        font-size: 16px;
        font-family: @headings-font-family;
        color: @navbar-default-link-color;
        padding: 0 0.8em;
      }
    }
  }

  .language {
    button {
      font-size: 16px;
      margin: 6px auto;
      font-family: @headings-font-family;

      .caret {
        margin: 0 0 3px 5px;
        font-size: 20px;
        border-width: 6px;
      }
    }
  }
}

#static {
  p.content {
    white-space: pre-wrap;
  }
}

// Plugin customisation
.datepicker {
  z-index: 1000 !important;
  &.datepicker-dropdown.datepicker-orient-bottom:before {
    top: -14px;
  }
}

@media (max-width: @screen-xs-max) {
  h1 {
    font-size: 2em;
    margin-top: 5px;
  }

  .navbar .dropdown-menu {
    left: -40px;
  }

  #footer {
    padding-top: 1em;
    padding-bottom: 1em;

    .static-links {
      text-align: center;
      ul {
        display: inline-block;
        float: none;
        padding: 10px 0;
      }
    }
  }

  .modal-dialog {
    width: 90%;
    margin-left: 3.5%;
  }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  #footer {
    .static-links ul {
      float: left;
      padding-bottom: 10px;
    }
  }

  .modal-dialog {
    width: 70%;
    &.planModal,
    &.mapMpdal {
      width: 90%;
    }
  }
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .modal-dialog {
    &.planModal,
    &.mapMpdal {
      width: 70%;
    }
  }
}

@media (min-width: @grid-float-breakpoint) {
  .navbar .navbar-nav {
    margin-left: -10px;
  }
}

.navbar .navbar-nav li:nth-child(n + 7) {
  display: none;
}

@media (max-width: @screen-md-max) {
  .navbar .navbar-nav li:nth-child(n + 5) {
    display: none;
  }
}

@media (max-width: @screen-sm-max) {
  .navbar .navbar-nav li:nth-child(n + 7) {
    display: none;
  }
}

@media (max-width: @screen-sm-min) {
}

@grid-float-breakpoint: @screen-sm;
@navbar-height: 54px;
@caret-width-base: 6px;

@font-size-medium: ceil((@font-size-base * 1.1)); // ~16px

@btn-border-radius-base: 0;
@btn-border-radius-large: 0;
@btn-border-radius-small: 0;

@desktop: ~"only screen and (min-width: 960px)";
@tablet: ~"only screen and (min-width: 767px)";
@smartphone: ~"only screen and (max-width: 766px)";

@media (max-width: 1200px) {
  #footer .static-links ul li a {
    font-size: 14px !important;
    padding: 0 0.5em;
  }
}

@media (min-width: 1200px) {
  #footer .static-links ul li a {
    padding: 0 0.5em;
  }
}

#homepage-global,
#search-banner {
  .banner {
    position: relative;

    .cycle-slideshow,
    .cycle-slideshow img {
      width: 100%;
      margin-bottom: 10px;
      @media @smartphone {
        height: 225px;
      }
      @media @tablet {
        height: 270px;
      }
      @media @desktop {
        height: 300px;
      }
    }

    .cycle-slideshow {
      @media @smartphone {
        height: 235px !important;
      }
      @media @tablet {
        height: 280px !important;
      }
      @media @desktop {
        height: 310px !important;
      }
    }

    .cycle-prev,
    .cycle-next {
      position: absolute;
      z-index: 123;
      left: 2%;
      top: 50%;
      transition: none;
      margin-top: -26px;
    }

    .cycle-next {
      left: auto;
      right: 2%;
    }

    .banner-search {
      position: absolute;
      left: 10%;
      top: 50%;
      margin-top: 30px;
      z-index: 100;
      background: url("../../img/70trans.png");
      padding: 5px;

      @row-height: 42px;

      .form-group {
        margin-right: 5px;
        float: left;

        .form-control,
        button,
        .input-group-addon {
          height: @row-height;
          border: none;
          border-radius: 0;
          color: @text-color;
          background-color: #ffffff;
          &:focus {
            border: none;
            box-shadow: none;
            outline: none;
          }
        }

        // button, .input-group-addon {
        //   height: @row-height;
        //   border: none;
        //   border-radius: 0;
        //   background-color: #ffffff;
        // }

        // .input-group-addon, .input-group-btn button {
        //   background-color: #ffffff;
        // }

        .input-group-btn {
          font-size: inherit;
        }
        button.selectpicker {
          font-size: 1.1em;
        }

        .searchbox {
          width: 350px;
          .input-group-addon {
            padding-left: 0.2em;
            padding-right: 0;
          }
        }

        &.cal {
          width: 175px;
          &.until {
            display: none;
          }
          button {
            padding-right: 0.2em;
          }
        }
      }
      &.has-date-value .cal.until {
        display: block;
        transition: 0.1s linear;
        -moz-transition: 0.1s linear;
        -webkit-transition: 0.1s linear;
      }
      .search-submit {
        height: @row-height;
        font-size: 1.2em;
      }
    }

    @media @smartphone {
      .banner-title {
        font-size: 2em;
        left: 5%;
      }
      .banner-search {
        margin-top: 15px;
        left: 5%;
        max-width: 90%;

        .form-group {
          &.searchbox-container {
            width: ~"calc(90% - 35px)";
            margin: 0;
            padding-right: 5px;
            .searchbox {
              width: 100%;
            }
          }
          .form-control {
            font-size: 0.8em;
          }
          &.cal {
            width: 165px;
          }
        }
      }
    }
  }
}

// Why book with us? box
#introBox {
  padding: 1em 1em 0;
  background-color: @blueGreyBackground;
  margin-bottom: 1em;

  // Just for FTBO
  .ftbo {
    h2 {
      text-transform: uppercase;
      font-size: 1.4em;
      margin-top: 0px;
      margin-bottom: 0.4em;
      background-color: transparent;
      padding: 0;
    }

    ul {
      margin-bottom: 0.5em;
    }

    li {
      margin-bottom: 0.3em;

      i {
        color: #514099;
      }
    }

    .trustmarks {
      img {
        max-width: 120px;
        width: 100%;
        text-align: center;
        margin: 0 0 1em;
      }
    }
  }
}

.bottomList {
  .rowItem {
    margin-bottom: 1em;

    .imageBlock {
      margin-bottom: 0.8em;

      img {
        .img-border();
        width: 100%;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    h4 {
      // font-family: @fontSerif;
      font-size: 1.5em;
      margin-top: 0px;
      margin-bottom: 0.4em;
    }

    .price {
      margin-bottom: 0.4em;
      font-size: 1.12em;
    }

    .btn {
      text-transform: uppercase;
    }
  }
}

.spacingBelow {
  margin-bottom: 1em;
}

.imageBlock {
  + h4 {
    margin-top: 0.4em;
  }
}

.errorlist {
  list-style: none;
  padding: 0;
  margin-top: 0.2em;
  color: @redColour;
}

// Datepickers on home & search pages
.datepicker,
.datepicker.dropdown-menu,
.datepicker td,
.datepicker th,
.datepicker td a,
.datepicker .datepicker-header,
.datepicker table tr td span.active.active {
  border-radius: 0;
}

.datepicker.dropdown-menu {
  border-width: 7px;
  border-style: solid;
  -webkit-box-shadow: -14px 14px 0 rgba(52, 73, 94, 0.06);
  box-shadow: -14px 14px 0 rgba(52, 73, 94, 0.06);
  z-index: 1000 !important;
}

.datepicker thead tr:first-child th {
  padding: 10px;
  color: #fff;
  font-size: 16px;
}

.datepicker-dropdown:after {
  display: none;
}

.datepicker.dropdown-menu th,
.datepicker.dropdown-menu td {
  padding: 5px 8px;
}

.datepicker.datepicker-dropdown:before {
  border-bottom-width: 7px;
  border-bottom-style: solid;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.datepicker table tr td.active,
.datepicker table tr td span.active.active {
  background-image: none;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover {
  background-image: none;
}

.datepicker .datepicker-days table tr td.day,
.datepicker .datepicker-days table tr th,
.datepicker.dropdown-menu .datepicker-days table tr td.day,
.datepicker.dropdown-menu .datepicker-days table tr th {
  padding: 0;
  width: 35px;
  height: 35px;
  border-radius: 0;
}

.datepicker table tr th,
.datepicker.dropdown-menu table tr td {
  border-radius: 0;
}

.datepicker table thead .dow,
.datepicker table tbody {
  background-color: white;
}
/* Datepicker styling */
.datepicker table tr td.day.new,
.datepicker table tr td.day.old {
  color: #8a8a8a;
}

.datepicker table tr td.day.disabled,
.datepicker table tr td.day.disabled:hover {
  color: #d3d3d3 !important;
  background: none !important;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.has-performance.active,
.datepicker table tr td.has-performance.active:hover {
  color: #ffffff;
}

.datepicker table tr td.day.today,
.datepicker table tr td.day.today.disabled,
.datepicker table tr td.day.today.disabled:hover {
  border-width: 2px;
  border-style: solid;
  background: none;
}

#cookie-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 320px;
  z-index: 150;
  background: #000;
  color: #fff;
  span {
    margin: auto;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    width: 100%;
  }
  a {
    color: #f8b834;
  }
  a:hover {
    color: #7766bf;
  }
  #cookie-close-button {
    width: 20px;
    display: inline-block;
    cursor: pointer;
  }
}
