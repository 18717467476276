@import "~bootstrap/less/bootstrap";
@import "generic";
@import "stars";

@desktop: ~"only screen and (min-width: 960px)";
@tablet: ~"only screen and (min-width: 767px)";
@smartphone: ~"only screen and (max-width: 766px)";

#homepage {
  h3 {
    text-transform: uppercase;
    margin: 0px 0px 0.8em;

    a {
      small {
        padding-left: 2em;
        color: inherit;
        i {
          margin-left: 0.5em;
        }
      }
    }
  }

  .boxed:not(.bg-brand) {
    background-color: @gray-darker;
    color: #fff;
    a {
      color: #fff;
      &:hover {
        color: darken(#fff, 10%);
      }
    }
  }

  .listItem {
    .clearfix();
    border-bottom: 1px solid @gray-lighter;
    margin-bottom: 1em;
    padding-bottom: 1em;

    .event-img {
      padding-right: 0;
    }

    img {
      .pull-left();
      margin-right: 1em;
      .img-border();

      &:hover {
        opacity: 0.8;
      }
    }

    h4 {
      font-size: 1.5em;
      margin-top: 0px;
      margin-bottom: 0.4em;
    }

    .btn {
      text-transform: uppercase;
    }

    .audienceRating {
      .pull-right();
      background-color: @blueGreyBackground;
      font-size: 0.85em;
      text-align: center;
      width: 100%;
      line-height: 1.3em;
      padding-bottom: 0.5em;

      .heading {
        font-family: @headings-font-family;
        text-transform: uppercase;
        font-size: 0.9em;
        color: #ffffff;
        padding: 0.2em 0.4em;
        text-align: center;
        margin-bottom: 0em;
      }

      .percentage {
        font-size: 3.4em;
        display: block;
        font-family: "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold",
          "HelveticaNeue", "Helvetica Neue", "TeXGyreHerosBold", "Helvetica", "Tahoma", "Geneva",
          "Arial", sans-serif;
        text-align: center;
        line-height: 1.1em;

        @media (max-width: @screen-xs-max) {
          font-size: 2.6em;
        }

        .sign {
          font-size: 0.7em;
        }
      }
    }
  }

  #topLeftList {
    h3 {
      color: #ffffff;
    }

    img {
      max-height: 80px;
    }

    .time-since {
      font-style: italic;
    }
  }

  #topRightList {
    h3 {
      color: @text-color;
    }
  }

  #latest-reviews {
    margin-top: 2em;

    .content-slider {
      width: 100%;
    }

    h3 {
      margin-bottom: 0px;
    }

    .review-content {
      background-color: @blueGreyBackground;
      padding: 1em;
      margin-bottom: 2em;
    }

    .review {
      h4 {
        margin-bottom: 0.2em;
        margin-top: 0px;
        // font-family: fontSansSerif;
        font-size: 1.8em;
      }

      h5 {
        font-weight: bold;
      }

      .star-container {
        margin-bottom: 0.3em;

        .fa {
          padding-right: 0.3em;
        }
      }

      .date-reviewed {
        margin-bottom: 0.4em;
        color: #666666;
      }

      .review-body {
        margin-bottom: 0.5em;
        white-space: pre-wrap;
      }

      .reviewer {
        font-style: italic;
        margin-bottom: 0em;

        .conf {
          font-style: normal;
          color: #666666;
          margin-left: 1em;
        }
      }
    }
  }
}

// Global-style home page
#homepage-global {
  .banner {
    .banner-title {
      position: absolute;
      left: 10%;
      top: 50%;
      margin-top: -25px;
      // margin-left: -15px;
      color: #fff;
      text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.37);
      font-weight: bold;
      left: 5%;
      @media @desktop {
        font-size: 3em;
      }
      @media @tablet {
        font-size: 2.3em;
      }
      @media @smartphone {
        font-size: 1.3em;
      }
    }
    #search-form .searchbox.keyword-missing {
      outline: 2px solid @redColour;
    }
  }

  .themes-home,
  .popular-places-home,
  .also-popular {
    @media @smartphone {
      padding-right: 0px;
    }
    a {
      .title {
        position: absolute;
        bottom: 0;
        left: 0.5em;
        color: #fff;
        // font-size: 1.1em;
        font-weight: 700;
        text-shadow: 2px 3px 2px rgba(0, 0, 0, 1);
        z-index: 123;
        white-space: pre-wrap;
      }
      .highlight {
        position: absolute;
        z-index: 122;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=00)";
        opacity: 0;
        transition: 0.2s linear;
        -moz-transition: 0.2s linear;
        -webkit-transition: 0.2s linear;
      }
      &:hover .highlight {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
        opacity: 0.4;
      }
    }
  }

  #primary-list,
  #secondary-list {
    max-width: 975px;
    margin-left: auto;
    margin-right: auto;
  }

  #primary-list {
    h2 {
      margin-bottom: 0.5em;
    }

    .popular-places-home {
      margin-bottom: 2em;
      padding-left: 0;

      > .row > .col-xs-6 {
        max-width: 310px;
      }

      a {
        display: inline-block;
        margin-bottom: 18px;
        position: relative;
        border-width: 3px;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
    .themes-home {
      margin-bottom: 3em;
      padding: 0;

      .carousel-wrapper {
        // width: 320px;
        max-width: 100%;
        @media (min-width: @screen-lg-min) {
          .pull-right();
        }
      }

      ul,
      ul li {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      ul.cycle-slideshow {
        max-width: 100% !important;
        width: 320px !important;
        overflow: hidden;
        height: auto;

        .cycle-carousel-wrap {
          width: 100%;
        }

        li {
          display: block;
          max-width: 50%;
          width: 160px;
          a {
            display: block;
            position: relative;
            width: 100%;
            border-width: 3px;

            img {
              width: 100%;
              height: auto;
            }

            .title {
              font-size: 1.4em;
            }
          }
        }
      }
    }
  }

  #secondary-list {
    margin-bottom: 3em;

    h2 {
      margin-bottom: 0.5em;
      max-width: 975px;
      &:after {
        content: "";
        margin-left: 187px;
        border-bottom: 3px solid #919191;
        display: block;
        margin-top: -8px;
      }
    }

    .carousel-wrapper {
      position: relative;

      .cycle-slideshow {
        width: 92% !important;
        margin-left: 4%;
        margin-right: 4%;

        .cycle-carousel-wrap {
          width: 100%;
        }

        .slide {
          margin-right: 0.8%;
          margin-left: 0.8%;
          width: 15%;
          max-width: 135px;
          a {
            position: relative;
            display: block;
            .clearfix();
            img {
              width: 100%;
              height: 100%;
            }
            .title {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              width: 100%;
            }
          }
        }
      }

      .carousel-controls {
        height: 100%;

        .cycle-prev,
        .cycle-next {
          position: absolute;
          bottom: 0;
          width: 3%;
          color: #fff;
          text-align: center;
          height: 100%;
          span {
            position: absolute;
            top: 50%;
            margin-top: -8px;
            left: 50%;
            margin-left: -4px;
          }
        }

        .cycle-prev {
          left: 0;
        }

        .cycle-next {
          right: 0;
        }
      }
    }
  }
}
