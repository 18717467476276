span.star-rating {
  &.fa-stack,
  .fa-stack {
    width: 1em;
    height: 2em;
  }

  span.fa-stack {
    i.fa-star-half {
      width: 60%;
    }
  }

  i.star-background {
    display: none;
  }

  &.star-colors-default {
    i.star-outline {
      display: none;
    }
    i.star-empty {
      display: none;
    }
    i.star-background {
      color: #e7e7e7;
      display: inline;
    }
  }

  &.star-colors-0 {
    i.star-color {
      color: #e7e7e7;
    }
  }

  &.star-colors-1 {
    i.star-color {
      color: #ee4535;
    }
  }

  &.star-colors-2 {
    i.star-color {
      color: #f89d42;
    }
  }
  &.star-colors-3 {
    i.star-color {
      color: #e0e143;
    }
  }
  &.star-colors-4 {
    i.star-color {
      color: #a6f857;
    }
  }
  &.star-colors-5 {
    i.star-color {
      color: #1cee3d;
    }
  }
}
